@font-face{
    font-family:MV Faseyha;
    src: local("MV Faseyha"),
         local("MVFaseyhaMhr"),
         url('/assets/fonts/MVFaseyhaMhr.woff2') format("woff2"),
         url('/assets/fonts/MVFaseyhaMhr.woff') format("woff");
    font-weight:400
}

@font-face{
    font-family: MV Waheed;
    src: local("MV Waheed"),
         local("MVWaheed"),
         url('/assets/fonts/MVWaheed.woff2') format("woff2"),
         url('/assets/fonts/MVWaheed.woff') format("woff");
    font-weight:400
}

$blue-gray-100: hsl(204, 14%, 35%);
$blue-gray-200: hsl(204, 14%, 40%);
$blue-gray-300: hsl(204, 14%, 50%);
$blue-gray-400: hsl(204, 14%, 60%);
$blue-gray-500: hsl(204, 14%, 70%);
$blue-gray-600: hsl(204, 14%, 80%);
$blue-gray-700: hsl(204, 14%, 90%);
$blue-gray-800: hsl(204, 14%, 95%);
$blue-gray-900: hsl(204, 14%, 97%);


body {
    font-family: 'MV Faseyha', sans-serif;
    font-size: 1.25rem;
}

.page-nav-buttons{
    font-family: 'MV Waheed', 'MV Faseyha', sans-serif;
    font-size: 1.4rem;
}

h1,
h2,
h3,
h4,
h5,
.navbar,
.case-list button,
.btn {
    font-family: 'MV Waheed', 'MV Faseyha', sans-serif;
    font-weight: 400 !important;
    line-height: 1.6;
    letter-spacing: 0.5px;
}


.post--horizontal {
    .post__details {
        align-items: center;
        padding: 0 25px 0 0;
        width: 80%;
    }
}

p, .page__content { 
    font-size: 1.2rem;
    padding-bottom: 12px;
    letter-spacing: 0.2px;
    line-height: 2;
}

h4 { line-height: 1.5; }
h5 { line-height: 1.6; }

#top-nav {
    .navbar-nav { direction: rtl  !important; }

    .nav-link {
        font-weight: normal;
        font-size: 1.3rem;
        padding-left: 12px;
        padding-right: 12px;
    }

    .dropdown-menu { 
        font-size: 1.1rem !important; 
        padding: 0;
    }
}

.logo {
    &__name { 
        h2 {
            font-size: 1.125rem;
        }
    }
}


#page {
    .page__section {

        font-family: 'MV Faseyha', sans-serif;

        .section__title {
            font-family: 'MV Waheed', 'MV Faseyha', sans-serif;
            padding-bottom: 5px;

            &__text {
                font-size: 2.2rem;
            }
        }
    }
}


.announcement {
    &__title {
        font-family: 'MV Waheed', 'MV Faseyha', sans-serif;
    }
}

.announcement-list {
    .item-title {
        font-family: 'MV Waheed', 'MV Faseyha', sans-serif;
        font-size: 1.35rem;
    }
    .item-time {
        font-size: 1rem;
    }
}

// Post
.post {
    h3 {
        font-size: 1.2rem;
        font-weight: 400; 
    }
    .post__title {
        text-align: right;
    }
}

.post--featured {
    h3 { 
        font-size: 1.8rem;
    }
    &.post--horizontal {
        .post__details {
            width: 40%;
            display: flex;
            align-items: right;
            flex-direction: column;
            justify-content: right;
            text-align: right;
        }
    }
}

.actions__button {
    h3 {
        font-size: 1.2rem;
    }
}

.banner .accordion-item .accordion-button {
    background-color: transparent;
    color: #fff;
    font-size: 1.5rem;
}

// Table
table {
    th {
        font-family: 'MV Waheed', 'MV Faseyha', sans-serif;
        font-weight: normal;
    }
    
}

// Misc

legend, .form-label, label {
    font-family: 'MV Waheed', 'MV Faseyha', sans-serif;
    color: $blue-gray-200;
}

.filter {
    font-family: 'MV Waheed', 'MV Faseyha', sans-serif;
}
.badge {
    font-weight: normal;
}

.sidebar {
    ul {
        text-align: right;
    }
}

.post__date {
    font-size: 0.9rem;
}

#page-footer {
    font-size: 1rem;
    h5 { font-size: 1.3em; }
}

/* Media Queries */

// X-Small devices (portrait phones, less than 576px)
@media (max-width: 575.98px) {
    .person__name {
        text-align: center;
    }
}

@media (max-width: 991.98px) {
    .actions__button {
        h3 { 
            font-size: 1.1rem;
         }
    }
}

@media all and (min-width: 1199.98px) {
    .logo {
        &__name {
            h2{
                font-weight: 400 !important;
                font-size: 1.6rem;
                line-height: 1.6rem;
                margin-top:1rem;
            }
            p {
                font-size: 0.9rem;
            }
        }
    }
}
